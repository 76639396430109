<template>
    <div class="quality-course">
        <div class="course-left" v-show="hideLeft">
            <div class="course-logo">精品课程</div>
            <div class="course-now">
                <div class="course-cover">
                    <img :src="course.course_cover"
                         alt="">
                </div>
                <div class="course-info">
                    <h3 class="title text-overflow">{{course.course_name}}course</h3>
                    <p class="info text-overflow">{{course.course_desc}}</p>
                    <p class="info text-overflow">{{course.admin_name}}</p>
                    <div class="info-bottom">
                        <el-button type="primary" class="btn-orange" size="mini" @click="toIndex">返回课程</el-button>
                    </div>
                </div>
            </div>
            <el-scrollbar class="course-catalog-detail" style="height: 100%" :native="false">
                <div class="catalog-detail-wrapper">
                    <div class="catalog-title">
                        <h3 class="title">课程目录</h3>
                        <p class="subtitle">已完结{{course.chapter_num}}章</p>
                    </div>
                    <el-collapse v-model="courseCatalogActive" class="collapse-content">
                        <el-collapse-item :name="courseCatalogIndex"
                                          v-for="(courseCatalogItem, courseCatalogIndex) in course.course_ex_content"
                                          :key="courseCatalogIndex">
                            <template slot="title">
                                <span>{{courseCatalogItem.name}}（{{courseCatalogItem.children.length}}节）</span>
                            </template>
                            <el-collapse v-model="courseCatalogActive" class="collapse-content collapse-content-two">
                                <el-collapse-item :name="courseCatalogTwoIndex"
                                                  v-for="(courseCatalogTwoItem, courseCatalogTwoIndex) in courseCatalogItem.children"
                                                  :key="courseCatalogTwoIndex">
                                    <template slot="title">
                                        <span>{{courseCatalogTwoItem.name}}</span>
                                    </template>
                                    <div class="chapter-item"  @click="showResource(courseCatalogThreeItem)"
                                                 :class="{current : currentRes === courseCatalogThreeItem.resource_id}"
                                                 v-for="(courseCatalogThreeItem, courseCatalogThreeIndex) in courseCatalogTwoItem.children"
                                                 :key="courseCatalogThreeIndex">
                                        <span>{{courseCatalogThreeItem.name}}</span>
                                        <i class="iconfont" v-if="courseCatalogThreeItem.haveRead">&#xe8e4;</i>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </el-scrollbar>
        </div>
        <div class="course-right">
            <div class="right-header">
                <div class="header-left">
                    <i class="iconfont icon-screen" :class="{current : showScreenFull}"
                       @click="toggleScreen">&#xe660;</i>
                    <div class="course-title">
                        {{title}}
                    </div>
<!--                    <router-link to="/qualityCourse" target="_blank" class="see-detail-btn" v-show="showScreenFull">返回课程目录-->
<!--                    </router-link>-->
                </div>
                <UserInfo></UserInfo>
            </div>
            <iframe :src="src" v-if="src" frameborder="0" width="100%" style="height: inherit;"></iframe>
<!--            <el-scrollbar class="right-content-wrapper" ref="rightContent" style="height: 100%;" :native="false">-->

<!--                <div class="right-content detail-content">-->

<!--                </div>-->
<!--                &lt;!&ndash;<div class="detail-operate operate-left">-->
<!--                    <div class="item-operate">-->
<!--                        <i class="iconfont">&#xe8ef;</i>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="detail-operate operate-right">-->
<!--                    <div class="item-operate" @click="toggleScreen">-->
<!--                        <i class="iconfont" v-if="hideLeft" title="全屏阅读">&#xe8fa;</i>-->
<!--                        <i class="iconfont" v-else title="退出全屏">&#xe8fb;</i>-->
<!--                    </div>-->
<!--                    <div class="item-operate">-->
<!--                        <i class="iconfont">&#xe8f1;</i>-->
<!--                    </div>-->
<!--                    <div class="item-operate" @click="toTop">-->
<!--                        <i class="iconfont">&#xe65a;</i>-->
<!--                    </div>-->
<!--                </div>&ndash;&gt;-->
<!--            </el-scrollbar>-->
        </div>
    </div>
</template>

<script>
    import UserInfo from 'components/userInfo/UserInfo'

    export default {
        data() {
            return {
                courseId: Number(this.$route.query.courseId) || '',
                courseCatalogActive: [0, 0],
                courseCatalogIndex: 0,
                courseCatalogList: [
                    {
                        id: 1,
                        levelOne: '第一章：MongoDB再入门',
                        courseCatalogTwoList: [
                            {
                                id: 11,
                                levelTwo: '01 | 学习编程，我到底该选择哪门语言？',
                                courseCatalogThreeList: [
                                    {
                                        id: 111,
                                        levelThree: '001 | 学习编程，我到底该选择哪门语言？',
                                        haveRead: 1
                                    },{
                                        id: 1111,
                                        levelThree: '001 | 学习编程，我到底该选择哪门语言？',
                                        haveRead: 1
                                    },{
                                        id: 1112,
                                        levelThree: '001 | 学习编程，我到底该选择哪门语言？',
                                        haveRead: 0
                                    },{
                                        id: 1113,
                                        levelThree: '001 | 学习编程，我到底该选择哪门语言？',
                                        haveRead: 0
                                    },
                                ]
                            },
                            {
                                id: 12,
                                levelTwo: '02 | 第一个程序：教你输出彩色的文字\n',
                                courseCatalogThreeList: [
                                    {
                                        id: 112,
                                        levelThree: '资源第三级资源第三级资源第三级资源第三级',
                                        haveRead: 0
                                    }
                                ]
                            },
                            {
                                id: 13,
                                levelTwo: '03 | 判断与循环：给你的程序加上处理逻辑',
                                courseCatalogThreeList: [
                                    {
                                        id: 113,
                                        levelThree: '资源第三级资源第三级资源第三级资源第三级',
                                        haveRead: 0
                                    }
                                ]
                            },
                            {
                                id: 14,
                                levelTwo: '04 | 随机函数：随机实验真的可以算 π 值嘛？',
                                courseCatalogThreeList: [
                                    {
                                        id: 114,
                                        levelThree: '资源第三级资源第三级资源第三级资源第三级',
                                        haveRead: 0
                                    }
                                ]
                            },
                            {
                                id: 15,
                                levelTwo: '05 | 数组：一秒钟，定义 1000 个变量',
                                courseCatalogThreeList: [
                                    {
                                        id: 115,
                                        levelThree: '资源第三级资源第三级资源第三级资源第三级',
                                        haveRead: 0
                                    }
                                ]
                            }
                        ]
                    }, {
                        id: 2,
                        levelOne: '第二章：从熟练到精通的开发之路',
                        courseCatalogTwoList: [
                            {
                                id: 21,
                                levelTwo: '01 | 学习编程，我到底该选择哪门语言？',
                                courseCatalogThreeList: [
                                    {
                                        id: 211,
                                        levelThree: '资源第三级资源第三级资源第三级资源第三级',
                                        haveRead: 0
                                    }
                                ]
                            },
                        ]
                    },{
                        id: 3,
                        levelOne: '第三章：分片集群与高级运维之道',
                        courseCatalogTwoList: [
                            {
                                id: 31,
                                levelTwo: '01 | 学习编程，我到底该选择哪门语言？',
                                courseCatalogThreeList: [
                                    {
                                        id: 311,
                                        levelThree: '资源第三级资源第三级资源第三级资源第三级',
                                        haveRead: 0
                                    }
                                ]
                            },
                        ]
                    },{
                        id: 4,
                        levelOne: '第四章：企业架构师进阶之法',
                        courseCatalogTwoList: [
                            {
                                id: 41,
                                levelTwo: '01 | 学习编程，我到底该选择哪门语言？',
                                courseCatalogThreeList: [
                                    {
                                        id: 411,
                                        levelThree: '资源第三级资源第三级资源第三级资源第三级',
                                        haveRead: 0
                                    }
                                ]
                            },
                        ]
                    },
                ],
                hideLeft: 1,
                showScreenFull: 0,
                title: '',
                course: {
                    course_name:'',
                    course_type: 1,
                    admin_name: null,
                    course_desc: '',
                    course_cover: '',
                    chapter_num: 0
                },
                src: null,
                currentRes: 0
            }
        },
        components: {
            UserInfo
        },
        created() {
            if (this.courseId === '') {
                this.courseId = this.courseCatalogList[0].courseCatalogTwoList[0].courseCatalogThreeList[2].id;
            }
            this.getCourseInfo();
        },
        watch: {
            $route: function (newRoute) {
                this.courseId = newRoute.query.courseId
            }
        },
        methods: {
            toggleScreen() {
                if (this.hideLeft === 1 && this.showScreenFull === 0) {
                    this.hideLeft = 0;
                    this.showScreenFull = 1
                } else {
                    this.hideLeft = 1;
                    this.showScreenFull = 0
                }
            },
            toTop() {
                this.$refs.rightContent.wrap.scrollTop = 0;
            },
            getCourseInfo() {
                let params = {
                    id: this.courseId
                }
                this.$shttp.axiosGetBy(this.$api.courseContent, params, (res) => {
                    if (res.code === 200) {
                        this.course = res.data;
                        this.chapters = res.data.course_ex_content;
                        this.lessonHeader = res.data.course_ex_header;
                    //    默认选择第一章第一节
                        if (res.data.course_ex_content[0].children.length) {
                            let resource = res.data.course_ex_content[0].children[0].children[0];
                            this.showResource(resource);
                        }


                    }
                }, (err) => {
                    console.log(err);
                });
            },
            showResource(resource) {
                this.title = resource.name;
                this.src = resource.file_preview_path;
                this.currentRes = resource.resource_id;
            },
            toIndex() {
                this.$router.push({name: 'qualityCourse'});
            }
        }
    }
</script>

<style scoped lang="scss">
    .quality-course {
        /*height: calc(100% - 84px);*/
    }
    .course-now {
        display: flex;
        padding: 50px 20px 20px 40px;
        position: relative;
        .course-cover {
            width: 178px;
            height: 100px;
            overflow: hidden;
            background: #fdf6ef;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .course-info {
            width: 1%;
            flex: 1;
            position: relative;
            margin-left: 10px;
            .title {
                margin: 0;
                font-size: 16px;
                color: #1C1F21;
            }
            .info {
                margin: 8px 0 0;
                font-size: 12px;
                color: #888;
                line-height: 1;
            }
            .info-bottom {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: space-between;
            }
        }
        &:after {
            content: '';
            position: absolute;
            left: 40px;
            right: 20px;
            bottom: 0;
            height: 1px;
            background: #e9e9e9;
        }
    }
    .course-catalog-detail {
        height: 1%;
        flex: 1;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding-bottom: 50px;
            }
        }
        .catalog-detail-wrapper {
            .catalog-title {
                padding: 40px 22px 20px 38px;
                .title {
                    margin: 0;
                    font-size: 24px;
                    padding-left: 15px;
                    position: relative;
                    height: 40px;
                    line-height: 40px;
                    color: #404040;
                    font-weight: 600;
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 7px;
                        height: 24px;
                        width: 3px;
                        background: #fa8919;
                    }
                }
                .subtitle {
                    margin: 0;
                    font-size: 14px;
                    line-height: 20px;
                    color: #888;
                }
            }
            .collapse-content {
                ::v-deep .el-collapse-item__header {
                    padding: 20px 22px 20px 38px;
                    font-size: 17px;
                    color: #000;
                    line-height: 30px;
                    font-weight: 600;
                    height: auto;
                    background-color: transparent;
                    span {
                        margin-right: 10px;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                ::v-deep .el-collapse-item {
                    &.is-active {
                        .el-collapse-item__header {
                            padding-bottom: 0;
                        }
                    }
                }
                ::v-deep .el-collapse-item__wrap {
                    background-color: transparent;
                    .el-collapse-item__content {
                        padding-bottom: 10px;
                        .chapter-item {
                            padding: 20px 22px 20px 38px;
                            line-height: 17px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: #000;
                            cursor: pointer;
                            span {
                                font-size: 14px;
                                width: 235px;
                                margin-left: 14px;
                                padding-left: 14px;
                                line-height: 20px;
                            }
                            .iconfont {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #fa8919;
                                font-size: 16px;
                                padding-right: 8px;
                            }
                            &.current {
                                span {
                                    color: #fa8919;
                                }
                            }
                            &:hover {
                                background-color: #fef6ee;
                                color: #fa8919;
                            }
                        }
                    }
                }
            }
            .collapse-content-two {
                border-top: none;
                border-bottom: none;
                ::v-deep .el-collapse-item__header {
                    font-size: 15px;
                    color: #4c4c4c;
                    border-bottom: none;
                    font-weight: 400;
                    span {
                        margin-left: 20px;
                        position: relative;
                        &:before {
                            content: '';
                            position: absolute;
                            left: -20px;
                            top: 12px;
                            width: 6px;
                            height: 6px;
                            background: #fa8919;
                        }
                    }
                }
                ::v-deep .el-collapse-item__wrap {
                    border-bottom: none;
                }
            }
        }
    }
    .detail-content {
        .detail-title {
            font-size: 28px;
            color: #404040;
            font-weight: 600;
            line-height: 1.5;
            margin-top: 60px;
        }
        .detail-author {
            color: #888;
            font-size: 16px;
            margin-top: 10px;
            span {
                margin-right: 5px;
                font-weight: 500;
            }
        }
        .detail-video {
            margin-top: 30px;
            video {
                display: block;
                outline: none;
            }
        }
        .detail-content {
            margin-top: 40px;
            p {
                margin: 0 0 30px;
                font-size: 17px;
                line-height: 30px;
                color: #353535;
            }
        }
        .detail-copyright {
            color: #b2b2b2;
            font-size: 14px;
            padding: 20px 0;
            margin-top: 60px;
            border-top: 1px solid #e2e2e2;
        }
    }
    .detail-operate {
        position: absolute;
        top: 60%;
        transform: translateY(-60%);
        &.operate-left {
            left: calc(50% - 340px - 80px);
        }
        &.operate-right {
            right: calc(50% - 340px - 80px);
        }
        .item-operate {
            width: 38px;
            height: 38px;
            line-height: 38px;
            color: #999;
            background: #f6f7f9;
            border: 1px solid #f6f7f9;
            text-align: center;
            border-radius: 50%;
            font-size: 24px;
            margin-top: 20px;
            cursor: pointer;
            &:hover {
                color: #fa8919;
                background: #fff;
                border: 1px solid #fa8919;
            }
        }
    }
    @media screen and (max-width: 1221px) {
        .quality-course {
            .course-left {
                display: none;
            }
            .course-right {
                .icon-screen {
                    transform: rotate(180deg) !important;
                }
            }
        }
    }
</style>