<template>
    <div>
        <el-popover v-model="userInfoPopover" placement="bottom-end" trigger="click" width="138" popper-class="user-info">
            <div class="user-info-popover">
                <div class="info-top">
                    <div class="name text-overflow">周清云</div>
                    <div class="school">清华大学</div>
                </div>
                <div class="info-list">
                    <router-link to="" class="info-item">
                        <i class="iconfont">&#xe8a6;</i>
                        <span>我的课程</span>
                    </router-link>
                </div>
                <a href="javascript:;" class="info-bottom" @click="userInfoPopover = false">
                    <span>退出登录</span>
                </a>
            </div>
            <el-button slot="reference" type="text">
                <i class="iconfont user-icon">&#xe736;</i>
            </el-button>
        </el-popover>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userInfoPopover: false
            }
        }
    }
</script>

<style scoped>

</style>